.news-mosaic {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 8px;
  }
  
  .news-item {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 20px;
    color: white;
    height: 250px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 20px;
    width: 100%;
    transition: transform 0.3s ease;
  }
  
  .news-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    display: none;
  }
  
  .news-item h3 {
    margin: 0;
  }
  
  .news-item a {
    color: yellow;
    text-decoration: none;
  }
  
  .news-item:hover {
    transform: scale(1.1);
  }

  .news-title{
    text-align: center;
  }

  @media only screen and (max-width: 1199px) {

    .news-item:hover {
      transform: scale(1.05);
    }

    .news-item {
      text-decoration: none !important;
    }

    .news-content {
      display: inline;
    }

    .news-title{
      display: none !important;
    }

  }