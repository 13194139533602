* {
  scroll-behavior: smooth;
  scroll-padding: 70px;
}

.custom-list {
  list-style-type: none;
  padding-left: 0;
}

.custom-list li {
  display: flex;
  align-items: start;
}

.custom-list li i {
  margin-right: 1px;
}

.no-style {
  text-decoration: none !important;
}

.go-up-button {
  position: fixed;
  bottom: 100px;
  left: 50px;
  background-color: #4f1472;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: xx-large;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease;
  opacity: 0.5;
}

.go-up-button:hover {
  background-color: #481b84;
  /* Darker blue on hover */
}

.content-wrapper {
  padding-top: 20px;
  font-size: larger;
}

.menuBox {
  border-right: white;
  /*#dee2e6 !important;*/
  border-top: white !important;
  border-left: white !important;
  border-bottom: white !important;
  border-style: solid !important;
  padding-right: 0px;
}

.leftSideBar {
  top: 170px;
  overflow-y: auto;
  font-size: x-large;
  overflow-x: hidden;
}

.leftSideBar .list-group-item {
  border-right: #dee2e6 !important;
  border-left: white !important;
  text-decoration: none !important;
  color: #000;
  cursor: pointer;
  list-style-type: circle;
}

.leftSideBar .list-group-item a {
  text-decoration: none !important;
  color: #000;
}

.leftSideBar .list-group-item.active {
  background-color: #909294 !important;
  border-color: #909294;
  opacity: .8;
  color: #fff;
}

.table .list-group-item {
  border: 0;
}

.content-wrapper .table-no-border {
  border-color: #fff;
}

.leftSideBar .list-group-item:hover {
  background-color: #dee2e6;
}

.leftSideBar .list-group {
  border-radius: 0% !important;
}

.rightSideBar {
  background-image: url("./images/we24intBARRA.png");
  background-size: 200%;
  opacity: 0.3;
}

.AppHeader {
  /* background-color: #471b84 !important;*/
  background-color: #161616 !important;
  font-size: medium;
  font-weight: 300;
}

.topLogo {
  width: 300px !important;
}

.welcomeText {
  font-size: large;
  text-align: justify;
}

.welcome {
  background-color: #161616;
  color: #fff;
  text-align: center;
}

.cardItemPurple {
  background: #471b84;
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.customDropDownItem {
  text-decoration: none;
  color: #000 !important;
}

.customDropDownItem:hover {
  background-color: unset !important;
  color: #4f1472 !important;
}

.bgColorLeft {
  /* background-color: #471b84; */
  color: #fff;
  text-align: left;
}

.footerUPCH {
  color: #fff;
  text-align: center;
}

.footerUPCHNav {
  color: #fff;
  text-align: right;
}

.footerUPCHNav:hover {
  color: #fff;
  text-decoration: underline;
}

.footer {
  position: inherit;
  background-color: #161616;
  background-image: url("./images/202x4.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: left;
  font-size: medium;
}

.footerLink {
  cursor: pointer;
}

.footerLink:hover {
  color: #777575 !important;
}

.second-menu {
  font-weight: 600;
  font-size: large;
  background-color: #353535 !important;
  /* background-color: #73108c !important; */
}

.second-menu-item {
  color: #fff !important;
  padding-right: 30px !important;
}

.second-menu-item:hover {
  color: #777575 !important;
}

.itemTopHeader {
  padding-right: 30px !important;
}

.Full {
  height: 100px;
}

.platinum {
  background: linear-gradient(300deg, #D5D6D8, #D5D6D8, #8f8e8e, #56565a, #8f8e8e, #D5D6D8, #D5D6D8);
  background-size: 200% 200%;
  animation: gradient-move 5s linear infinite;
  padding-left: 16px;
  padding-right: 20px;
  padding-top: 6px;
  padding-bottom: 5px;
  font-weight: 500;
  color: #fff;
}

.platinum:hover {
  background: linear-gradient(300deg, #D5D6D8, #D5D6D8, #8f8e8e, #161616, #8f8e8e, #D5D6D8, #D5D6D8);
  background-size: 200% 200%;
  animation: gradient-move 5s linear infinite;
}

/* .middle{
  margin-top: 20px;
} */

.platinumNav {
  background: gray;
  display: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 9px 9px #fff500;
  }

  50% {
    box-shadow: 0px 0px 12px 12px #fff500;
    /* Increase shadow size */
  }

  100% {
    box-shadow: 0px 0px 9px 9px #fff500;
  }
}

.itemAspirante {
  color: #161616;
  background-color: #fff500;
  position: absolute;
  right: 50%;
  font-size: x-large;
  width: 170px;
  font-weight: 600;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  height: 100%;
  box-shadow: 0px 0px 9px 9px #fff500;
}

.itemAspiranteNoClick {
  color: #161616;
  /*#4f1472;*/
  background-color: #fff500;
  /*#feb801;*/
  position: absolute;
  right: 50%;
  font-size: large;
  width: 170px;
  height: 67px;
  font-weight: 600;
  border-radius: 0;
  text-align: center;
  height: 100%;
  box-shadow: 0px 0px 9px 9px #fff500;
  animation: pulse 2s infinite ease-in-out;
  /* Apply animation */
}

.itemAspiranteMobile {
  color: #161616;
  /*#4f1472;*/
  background-color: #fff500;
  /*#feb801;*/
  position: absolute;
  right: 50%;
  font-size: x-large;
  width: 170px;
  height: 67px;
  font-weight: 600;
  border-radius: 0;
  text-align: center;
  height: 100%;
  display: none;
}

.itemAspiranteNav {
  display: none;
}

.itemAspirante:hover {
  /* color: #4f1472; */
  background-color: #a8a301;
  /* background-color: #c48d03d3; */
}

.itemTopHeader:hover {
  color: #777575 !important;
}

.TextMenu,
.TextMenu a {
  color: #fff ;
}

.DropDownMenu {
  background-color: #481b84 !important;
}

body {
  font-family: 'Kanit', sans-serif !important;
  font-weight: 300 !important;
  font-display: swap;
}

.SearchBar {
  background-color: #481b84;
  border: #481b84;
  color: #fff;
  width: 150px;
}

.gris1 {
  background-color: #505050;
}

.gris2 {
  background-color: #414141;
}

.gris3 {
  background-color: #353535;
}

.gris4 {
  background-color: #161616;
}

.gris5 {
  background-color: #505050;
}

.gris6 {
  background-color: #505050;
}

.SearchBar::placeholder {
  color: #fff;
}

.vr_medium {
  width: 3px;
  border-radius: 20%;
  height: 25px;
}

@keyframes gradient-move {
  0% {
    background-position: 200% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 1199px) {
  .platinum {
    display: none;
  }

  .platinumNav {
    display: inline;
    background: none;
  }

  .colAspirante {
    display: none;
  }

  .topLogo {
    width: 200px !important;
  }

  .footer {
    background-color: #161616;
    background-size: 150%;
    color: #fff;
    text-align: right;
    font-size: small;
  }

  .itemAspiranteNav {
    display: block;
  }

  .itemAspirante {
    position: relative;
    padding-left: auto;
    margin-left: auto;
    height: 50%;
  }

  .itemAspiranteNoClick {
    position: relative;
    padding-left: auto;
    margin-left: auto;
    height: 50%;
  }

  .itemAspiranteNav {
    display: none;
  }

}

@media only screen and (max-width: 767px) {
  .custom-list li {
    justify-content: end;
  }


  .itemAspirante {
    display: none;
  }

  .itemAspiranteMobile {
    z-index: 400;
    display: block;

    height: 100px;
  }

}

@media only screen and (max-width: 1000px) {

  .itemAspirante {
    display: none;
  }

  .itemAspiranteNoClick {
    display: none;
  }

  .itemAspiranteNav {
    display: block;
    color: #161616 !important;
    background-color: #fff500;
    border-radius: 0;
    text-align: center;
    height: 100%;
    box-shadow: 0px 0px 9px 9px #fff500;
    animation: pulse 2s infinite ease-in-out;
  }

  .itemAspiranteNav a{
    color: #161616 !important;
  }
}

@media only screen and (min-width: 768px) {

  .custom-list li {
    text-align: left;
    justify-content: start;
  }
}

.dropdown-menu {
  border-radius: 0px !important;
  background-color: #ffffffc0 !important;
  color: #000000;
  box-shadow: 10px 10px 12px #0000009c !important;
}

.customNavDropDown .dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  color: #000 !important;
}

.dropdown-item:hover {
  background-color: unset !important;
  color: #4f1472 !important;
}

.customNavDropDown:hover .dropdown-menu {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: unset;
}